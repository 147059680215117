<template>
  <Layout :navigation="navigationObj">
    <router-view />
  </Layout>
</template>

<script>
import Layout from "../components/Layout";

export default {
  name: "Deposit",
  data() {
    return {
      navigationObj: {
        items: [
          {
            title: "Collection Points",
            name: "collection-points",
            routeName: "collection-points",
          },
          {
            title: "Create collection point",
            name: "create-collection-point",
            routeName: "create-collection-point",
          }
        ],
      },
    };
  },
  components: {
    Layout,
  },
};
</script>

<style scoped>
</style>
